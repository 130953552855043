.presenter__container {
  grid-row: 1 / 4;
  grid-column: 1 / 3;
  -ms-grid-column: 1;
  -ms-grid-column-span: 3;
  -ms-grid-row: 1;
  -ms-grid-row-span: 4;
  display: grid;
  height: 95vh;
  padding: 10px 10rem;
  grid-template-rows: 124px 1fr 50px;
  grid-template-columns: 100%;
  -ms-grid-columns: 100%;
  -ms-grid-rows: 124px 1fr 50px;
  display: -ms-grid;
}

.presenter__question {
  display: grid;
  border-radius: 5px;
  grid-row: 2;
  border: 1px solid grey;
  font-size: 40px;
  background-color: #f5f2ef;
  grid-template-rows: 1fr 10px;
  padding: 24px;
}

.presenter__asked-by {
  font-size: 40px;
  text-align: right;
  padding: 0;
  margin: 0;
}

.progress {
  align-self: center;
  justify-self: end;
  grid-row: 3;
}
