 
.event-table__head {
    background-color: "#e4e3e4" !important;
}
.event-table__gray {
    background-color: "#f6f5f7" !important;
}
.event-table__white {
    background-color: "inherit" !important;
}
