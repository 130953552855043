 
.templateChecklist {
    margin-top: 4px;
}
.radio {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
}

.p-9 {
    padding: 9px;
}