.pulse-red {
    background-color: inherit;
    animation-name: color;
    animation-duration: 2s;
    animation-iteration-count: infinite;
  }
  
  @keyframes color {
    0% {
      background-color: inherit;
    }
    50% {
      background-color: rgba(195, 24, 24, 0.7);
    }
    100% {
      background-color: inherit;
    }
  }