.headerContainer {
  grid-row: 1;
  grid-column: 1 / 3;
  white-space: nowrap;
  display: block;
  /* padding: 12px; */
  -ms-grid-row: 1;
  -ms-grid-column: 1;
  -ms-grid-column-span: 3;
  background-color: #ffffff;
}

.logo {
  max-width: 300px;
  /* object-fit: fill; */
}

.hartford {
  padding: 12px;
}

.sharepoint {
  background-color: #b6d3e9;
  color: #ffffff;
  height: 4rem;
  padding: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: Arial;
}

.sharepoint .text {
  padding: 1rem;
  font-weight: bolder;
}

.sharepoint a {
  color: black;
  text-decoration: none;
  /* no underline */
}

.rightSide {
  color: white;
  display: inline-block;
  vertical-align: top;
  height: 100%;
  padding-top: 12px;
  margin-left: 24px;
}
