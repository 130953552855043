 
.questions-utils__container {
    width: 250px;
    text-align: center;
    display: flex;
    flex-direction: column;
}
.questions-utils__buttons {
    display: flex;
    border-radius: 5px;
    flex-direction: column;
    /* gap: 8px; */
    margin-bottom: 24px;
    padding: 8px;
    background-color: #f6f6f6;
}
.questions-utils__buttons-status {
    color: red;
    margin: 0;
    padding: 0;
}
.questions-utils__attendance {
    background-color: #f6f6f6;
    border-radius: 5px;
}
h6.questions-utils__attendance-header {
    background-color: #e7e7e7;
    font-size: 18px;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    font-weight: 700;
    margin: 0;
    padding: 4px 0;
}

.questions-utils__attendance-total {
    display: flex;
    justify-content: space-between;
    padding: 0 8px;
}
