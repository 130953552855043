.sidenavContainer {
    display: inline-block;
    border: 1px solid black;
    height: fit-content;
    grid-row: 2;
    grid-column: 1;
    -ms-grid-row: 2;
    -ms-grid-column: 1;
    border-radius: 4px;
    width: 160px;
    height: 240px;
}
.sidenavContainer.nested {
    width: 190px;
    height: 454px;
}
.box {
    width: 100%;
    border: 1px solid black;
    display: block;
    border-radius: 4px;
}

.spacer {
    margin: 16px 0;
}
.innerList {
    display: grid;
    /* display: -ms-grid; */
    padding: 8px;
}
.innerLink {
    display: flex;
    height: 24px;
    margin-bottom: 8px !important;
    align-items: center;
}
.innerLink svg {
    margin-right: 10px;
}

.innerLink:nth-child(1) {
    grid-row: 1;
    /* -ms-grid-row: 1; */
}
.innerLink:nth-child(2) {
    grid-row: 2;
    /* -ms-grid-row: 2; */
}
.innerLink:nth-child(3) {
    grid-row: 3;
    /* -ms-grid-row: 3; */
}
.innerLink:nth-child(4) {
    grid-row: 4;
    /* -ms-grid-row: 4; */
}
.innerLink:nth-child(5) {
    grid-row: 5;
    /* -ms-grid-row: 5; */
    margin-bottom: 0 !important;
}
