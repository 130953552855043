.player--container {
  grid-row: 1 / 4;
  -ms-grid-row: 1;
  -ms-grid-row-span: 4;
  width: 80%;
  grid-column: 1 / -1;
  -ms-grid-column: 1;
  -ms-grid-column-span: 3;
  display: grid;
  display: -ms-grid;
  grid-template-columns: 1fr 300px;
  grid-template-rows: 130px auto 40px;
  -ms-grid-columns: 1fr 300px;
  -ms-grid-rows: 130px auto 40px;
  column-gap: 8px;
  justify-content: center;
  justify-self: center;
}

.player--container .footerContainer {
  grid-row: 3;
  -ms-grid-row: 3;
  padding: 4px 0;
}

.player--video__container {
  height: fit-content;
  grid-row: 2;
  grid-column: 1;
  min-width: 500px;
  -ms-grid-column: 1;
  -ms-grid-row: 2;
}

hr.player--video__divider {
  margin: 16px 0;
}

.player--video__player {
  width: 100%;
  -ms-flex: 0 1 auto;
}

.player--video__actions {
  padding: 8px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}

.player--video__actions .closed-caption {
  margin-right: 4px;
}

.player--extras__container {
  grid-column: 2;
  grid-row: 2;
  -ms-grid-column: 2;
  -ms-grid-row: 2;
  display: flex;
  flex-direction: column;
}

.player--extras__info,
.player--extras__questions,
.player--extras__additional-info,
.player--extras__links-list,
.player--video__video_section {
  border: 1px solid gray;
  border-radius: 5px;
  margin-bottom: 12px;
  padding: 12px;
  background-color: #eeeeee;
}

.player--closed-caption {
  grid-column: 1 / 3;
  -ms-grid-column: 1;
  -ms-grid-column-span: 2;
  grid-row: 3;
  -ms-grid-row: 3;
  width: 100%;
  height: 250px;
}

.player--extras__questions {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.player--extras__slido {
  display: flex;
  flex-direction: column;
  gap: 4px;
  min-height: 28rem;
  padding-bottom: 0.5rem;
}

.player--extras__slido iframe {
  height: 100%;
}

.player--extras__questions div.form-control {
  background-color: white;
}

.player--extras__questions .control {
  display: flex;
  align-items: center;
  gap: 12px;
}

.player--extras__links-list {
  display: flex;
  flex-direction: column;
}

.player--extras__questions .submit-btn {
  align-self: center;
}

.underlined {
  text-decoration: underline;
}

.no-event__wrapper {
  display: flex;
  flex-flow: column nowrap;
  align-content: center;
  align-items: center;
  grid-column: 1 / -1;
  -ms-grid-column: 1;
  -ms-grid-column-span: 3;
  grid-row: 1 / -1;
  -ms-grid-row: 1;
  -ms-grid-row-span: 3;
}

.no-event__box {
  background-color: #f5f2ef;
  margin-bottom: 12px;
  padding: 8px;
  border: 1px solid #ecebec;
  border-radius: 5px;
}

.no-event__box h2 {
  text-decoration: underline;
  margin-top: 4px;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .player--container {
    margin: 0 auto;
  }
  .player--video__container {
    margin-right: 12px;
    display: -ms-flexbox;
    flex-direction: column;
    -ms-flex-direction: column;
    justify-content: center;
  }
  .player--video__video_section {
    display: -ms-flexbox;
    flex-direction: column;
  }
}
