#root {
    height: 100%;
}

.App {
    display: grid;
    padding: 12px 12px 0 12px;
    /* font-family: "Gotham Light"; */
    grid-template-columns: auto 1fr;
    /* grid-template-rows: 130px auto 150px; */
    row-gap: 8px;
    column-gap: 8px;
    display: -ms-grid;
    /* -ms-grid-rows: 130px 1fr 150px; */
    /* -ms-grid-columns: auto 1fr; */
}

.check-icon {
    display: inline-block;
    vertical-align: middle;
    max-height: 42px;
    padding: 9px;
}

.root-grid-row-1 {
    grid-row: 1;
    -ms-grid-row: 1;
}

.root-grid-row-2 {
    grid-row: 2;
    -ms-grid-row: 2;
}

.root-grid-col-1 {
    grid-column: 1;
    -ms-grid-column: 1;
}

.root-grid-col-2 {
    grid-column: 2;
    -ms-grid-column: 2;
}

.main-content {
    grid-row: 2;
    grid-column: 2;
    overflow: auto;
    -ms-grid-row: 2;
    -ms-grid-column: 2;
}

.table__container {
    height: 100%;
}

.table__head th {
    background-color: #e4e3e4;
}

.table-and-form__wrapper {
    display: grid;
    display: -ms-grid;
    column-gap: 8px;
    height: 100%;
    grid-template-columns: minmax(400px, 1fr) minmax(0, 300px);
    padding: 0 8px;
    -ms-grid-columns: minmax(400px, 1fr) minmax(0, 300px);
}

.table-and-form__wrapper.col-2-250 {
    grid-template-columns: 1fr 250px;
    -ms-grid-columns: 1fr 250px;
}

.table-and-form__table {
    overflow: auto;
    grid-column: 1;
    -ms-grid-column: 1;
}

.table-and-form__form {
    grid-column: 2;
    -ms-grid-column: 2;
}

.table-and-form__form .utils__container {
    width: 250px;
    text-align: center;
    display: flex;
    flex-direction: column;
}

.table-and-form__form .utils__container .util .util__title {
    background-color: #e7e7e7;
    font-size: 18px;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    font-weight: 700;
    margin: 0;
    padding: 4px 0;
}

.table-and-form__form .utils__buttons {
    display: flex;
    border-radius: 5px;
    flex-direction: column;
    /* gap: 8px; */
    margin-bottom: 24px;
    padding: 8px;
    background-color: #f6f6f6;
}

.table-and-form__form .form-container .form {
    width: calc(100% - 12px);
}

.table-and-form__wrapper .form-container {
    display: flex;
    flex-direction: column;
    gap: 4px;
    align-items: center;
    text-align: center;
    background-color: #f6f6f6;
    height: fit-content;
    padding-bottom: 8px;
    border-radius: 5px;
}

.form-container .form__header {
    background-color: #e7e7e7;
    font-size: 18px;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    font-weight: 700;
    width: 100%;
}

.form-container .form {
    padding: 8px;
}

.form-control {
    min-width: 150px !important;
}

.form-control.date .MuiOutlinedInput-root {
    max-width: 250px !important;
}

.form-container .form .form-control {
    width: 100%;
}

.form-container .submit-btn {
    align-self: center;
}

.moderator__container {
    display: grid;
    width: 80%;
    display: -ms-grid;
    grid-row: 1 / -1;
    grid-column: 1 / -1;
    -ms-grid-row: 1;
    -ms-grid-row-span: 3;
    -ms-grid-column: 1;
    -ms-grid-column-span: 3;
    justify-content: center;
    grid-template-columns: auto 260px;
    -ms-grid-columns: auto 260px;
    padding: 24px;
    margin: 0 auto;
}

.questions-table {
    margin-right: 8px;
}

.block {
    display: block !important;
}

.flex {
    display: flex;
}

.flex-row {
    flex-direction: row;
}

.nowrap {
    flex-wrap: nowrap;
}

.text-wrap {
    word-break: break-word;
    white-space: break-spaces;
}

.flex-column {
    flex-direction: column;
}

.justify-content-between {
    justify-content: space-between;
}

.justify-content-around {
    justify-content: space-around;
}

.justify-content-center {
    justify-content: center;
}

.align-items-center {
    align-items: center;
}

.align-items-start {
    align-items: flex-start;
}

.align-items-end {
    align-items: flex-end;
}

.white-space-nowrap {
    white-space: nowrap;
}

.text-center {
    text-align: center;
}

.p-0 {
    padding: 0;
}

.p-1 {
    padding: 4px;
}

.p-2 {
    padding: 8px;
}

.p-3 {
    padding: 12px;
}

.p-4 {
    padding: 16px;
}

.p-5 {
    padding: 20px;
}

.m-0 {
    margin: 0;
}

.m-1 {
    margin: 4px;
}

.mb-1 {
    margin-bottom: 4px;
}

.mt-1 {
    margin-top: 4px;
}

.ml-1 {
    margin-left: 4px;
}

.mr-1 {
    margin-right: 4px;
}

.m-2 {
    margin: 8px;
}

.mb-2 {
    margin-bottom: 8px;
}

.mt-2 {
    margin-top: 8px;
}

.ml-2 {
    margin-left: 8px;
}

.mr-2 {
    margin-right: 8px;
}

.m-3 {
    margin: 12px;
}

.mb-3 {
    margin-bottom: 12px;
}

.mt-3 {
    margin-top: 12px;
}

.ml-3 {
    margin-left: 12px;
}

.mr-3 {
    margin-right: 12px;
}

.m-4 {
    margin: 16px;
}

.mb-4 {
    margin-bottom: 16px;
}

.mt-4 {
    margin-top: 16px;
}

.ml-4 {
    margin-left: 16px;
}

.mr-4 {
    margin-right: 16px;
}

.m-5 {
    margin: 20px;
}

.mb-5 {
    margin-bottom: 20px;
}

.mt-5 {
    margin-top: 20px;
}

.ml-5 {
    margin-left: 20px;
}

.mr-5 {
    margin-right: 20px;
}

.mx-1 {
    margin-left: 4px !important;
    margin-right: 4px !important;
}

.mx-2 {
    margin-left: 8px !important;
    margin-right: 8px !important;
}

.mx-3 {
    margin-left: 12px !important;
    margin-right: 12px !important;
}

.mx-4 {
    margin-left: 16px !important;
    margin-right: 16px !important;
}

.mx-5 {
    margin-left: 20px !important;
    margin-right: 20px !important;
}

.my-1 {
    margin-top: 4px !important;
    margin-bottom: 4px !important;
}

.my-2 {
    margin-top: 8px !important;
    margin-bottom: 8px !important;
}

.my-3 {
    margin-top: 12px !important;
    margin-bottom: 12px !important;
}

.my-4 {
    margin-top: 16px !important;
    margin-bottom: 16px !important;
}

.my-5 {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
}

.width-350 {
    width: 350px;
}

.width-300 {
    width: 300px;
}


/* If IE10/11 */

@media all and (-ms-high-contrast: none),
(-ms-high-contrast: active) {
    .table-and-form__table {
        padding-right: 8px;
    }
    .table__wrapper {
        padding-left: 12px;
    }
}