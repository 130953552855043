 
.ip-range__container {
    display: flex;
    flex-flow: row nowrap;
}

.ip-range-form__container {
    display: flex;
    flex-direction: column;
    width: 350px;
    gap: 10px;
    align-items: center;
    text-align: center;
    background-color: #f6f6f6;
    height: fit-content;
    padding-bottom: 8px;
    border-radius: 5px;
}
.ip-range-form__container h6 {
    background-color: #e7e7e7;
    font-size: 18px;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    font-weight: 700;
    width: 100%;
}
.ip-range-form__form {
    padding: 12px;
}

.ip-range-form__container .form-control {
    width: 100%;
}
.ip-range-form__container .submit-btn {
    align-self: center;
}
