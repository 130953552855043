 
.footerContainer {
    width: 100%;
    text-align: center;
    align-self: center;
    grid-row: 3;
    grid-column: 1 / 3;
    -ms-grid-row: 3;
    -ms-grid-column: 1;
    -ms-grid-column-span: 3;
}
