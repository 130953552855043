.file-form {
    display: flex;
    align-items: center;
    /* gap: 14px; */
}
.two-form__container {
    display: flex;
    width: 300px;
    flex-flow: column nowrap;
}
